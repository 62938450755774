import styled from 'styled-components';


const SubscribeWrapper = styled.div`
    margin: ${ ( { theme } ) => `${ theme.spacing04 } auto`};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export default SubscribeWrapper;
