import styled from 'styled-components';

export const Label = styled.label`
    font-size: ${ ( { theme } ) => theme.fontSizeXS };
    font-weight: ${ ( { theme } ) => theme.fontWeightBold };
    padding: 0;
    margin: 0;
`;

export default Label;
