import styled from 'styled-components';


const ButtonWrapper = styled.div`
    margin: ${ ( { theme } ) => `${ theme.spacing08 } 0`};
    display: grid;
    gap: ${ ( { theme } ) => `${ theme.spacing08 }`};
    width: 100%;
`;

export default ButtonWrapper;
