import styled from 'styled-components';


const CountDownWrapper = styled.div`
    margin: ${ ( { theme } ) => `${ theme.spacing07 } 0`};
    display: flex;
    justify-content: center;
`;

export default CountDownWrapper;
